<template>
  <div class="wrapper">



    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY </div>
        </div>
      </div>
    </header>

    <v-dialog v-model="showPopup" max-width="400" max-height="60vh" style="z-index:2000;" >
      <v-card >
        <v-card-title class="primary lighten-1 white--text " style="font-weight: bold;">
          <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="">CAT MONEY </div>
        </div>

          <v-spacer></v-spacer>
          <v-btn icon @click="showPopup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-for="(product, index) in product_show_popup" :key="index">
          <v-container style="padding-bottom: 0px; padding-top: 0px;">
            <v-row>
              <v-col cols="12">
                <v-card-text class="headline ">
                  {{ cats[product.type_product].name }}
                </v-card-text>
                <v-card-text>
                  <span>
                    <strong>Tiền đầu tư:</strong> {{ formatMoney(product?.amount) }} đ
                  </span>
                  <span>
                    <strong>Ngày bắt đầu :</strong> {{ calculateDate(product.created_at, 0) }}
                  </span>
                  <span>
                    <strong>Số ngày đầu tư:</strong> {{ product.investment_time }} {{product.type_product == 3 ? 'tháng' : 'ngày' }}
                  </span>
                  <span>
                    <strong>Ngày nhận lãi:</strong> {{ calculateDate(product.end_at, 0) }}
                  </span>
                  <span>
                    <strong>Doanh thu:</strong> {{ revenue(product?.amount, product?.investment_time, product.type_product) }} đ
                  </span>
                  <span v-if="product.status != 1 && product.status != 0">
                    <strong>Phí dịch vụ:</strong> {{service(product?.amount, product?.investment_time, product.type_product)}} đ  &#40;15%&#41;	
                  </span>
                  <span>
                    <strong>Tổng thanh toán:</strong> {{ tongThanhToan(product?.amount, product?.investment_time, product.type_product) }} đ
                  </span>
                  <span>
                    <strong>Tổng thực nhận:</strong> {{ serviceCharge(product?.amount, product.investment_time, product.type_product) }} đ
                  </span>
                  <span v-if="product.status == 2">
                    <strong>Thời gian thực hiện đầu tư đến 17:00 hôm nay</strong>
                  </span>
                </v-card-text>
                <!-- <span>Hoàn thành phí 20% Doanh thu cho người đầu tư trước</span>
                <span>Hoàn thành phí 20% Doanh thu cho người đầu tư trước</span> -->
              </v-col>
              <!-- <v-col cols="12" v-if="product.status != 1 && product.status != 0"> -->
                <!-- <v-card-text class="headline" style="font-weight: bold;">Thông tin ngân hàng người đâu tư trước :</v-card-text> -->
                <!-- <v-card-text> -->
                  <!-- <span>
                    <strong>Tài khoản USDT:</strong> {{ product?.usdt_account }}
                  </span> -->
                  <!-- <span>
                    <strong>Tên ngân hàng: </strong>{{ product.name_bank }}
                  </span>
                  <span>

                    <strong>Số tài khoản:</strong>  {{ product.number_bank }}
                  </span>
                  <span>

                    <strong>Tên chủ thẻ: </strong> {{ product.user_name_bank }}
                  </span> -->
                <!-- </v-card-text> -->
              <!-- </v-col> -->
              <v-col cols="12" v-if="product.status != 1 && product.status != 0">
                <v-card-text class="headline" style="font-weight: bold;">Thông tin tổng thanh toán gói đầu và phí dịch vụ  :</v-card-text>
                <v-card-text>
                  
                  <span>
                    <strong>Tên ngân hàng: </strong>{{ bank_config.name_bank }} 

                  </span>
                  <span>

                    <strong>Số tài khoản: </strong>{{ bank_config.number_bank }} 
                  </span> 
                  <span>

                    <strong>Tên chủ thẻ: </strong>{{ bank_config.user_name_bank }} 
                  </span>

                </v-card-text>
              </v-col>
            </v-row>
            <hr>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="" @click="showPopup = false">Đóng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section class="pack-suggest">
      <p class="main-heading">Gói đầu tư dành cho bạn</p>

      <div class="flex flex-col gap-y-10px">
        <!-- <div class="pack-card-overlay"></div> -->
        <div class="pack-card" :class="{'block': blocks.indexOf(index) !== -1}" v-for="(cat, index) in cats" :key="index">
          <img class="img" v-if="blocks.indexOf(index) !== -1" src="@/assets/img/lock.png" alt="">
          <div class="flex justify-between items-center">
            <p class="pack-card_name">{{ cat.name }}</p>
            <p class="pack-card_price">{{ cat.around }}</p>
          </div>
          <div class="pack-card_content">
            <div class="flex gap-x-3 items-center">
              <div class="pack-card_img">
                <img :src="cat.image" alt="" />
              </div>
              <div class="flex flex-col pack-card_desc">
                <div class="suggest-desc">
                  <p>• Thời gian đầu tư: </p>
                  <div class="ml-1">
                    <p v-for="r in cat.rose" :key="r">{{ r.text }}</p>
                  </div>
                </div>
                <p>• Phí dịch vụ: 15% / lãi</p>
                
                <p v-if="index == 3">• Quay thưởng may mắn nhận tài sản</p>

              </div>
            </div>

            <div>
              <div v-for="(status, i) in cat.status" :key="i" class="status-absolute"
              :class="{'reserved': i == 2, 'active': active_product.some(e => e.type == index && e.status == i)}"
              @click="ShowInfo(index, i)"
              >
 
                <div class="pack-card_status">
                  <p class="card-status status-waiting">{{ status.text }}</p>
                </div>
                <div class="status-img">
                  <img :src="status.icon" alt="" />
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
import moment from 'moment';

export default {
  components: { Footer },
  data() {
    return {
      blocks: [2,3],
      type: 0,
      showPopup: false,
      products: [],
      product_show_popup: [],
      active_product: [],
      bank_config: {},
      cats: [
        {
          name: 'CAT RED',
          rose: [
            { text: '3 ngày / 8% lãi' },
            { text: '5 ngày / 13% lãi' },
            { text: '7 ngày / 18% lãi' },
            { text: '12 ngày / 25% lãi' },
          ],
          around: 'VNĐ 2.000.000 - 25.000.000', image: require(`@/assets/img/cat-red.png`),
          status: [
            { text: 'Chờ xử lý', icon: require(`@/assets/img/waiting.png`) },
            { text: 'Đã mua', icon: require(`@/assets/img/checked.png`) },
            { text: 'Dự trữ', icon: require(`@/assets/img/saved.png`) },
            { text: 'Kháng cáo', icon: require(`@/assets/img/cancel.png`) }
          ]
        },
        {
          name: 'CAT SLIVER',
          rose: [
            { text: '3 ngày / 8% lãi' },
            { text: '5 ngày / 13% lãi' },
            { text: '7 ngày / 18% lãi' },
            { text: '12 ngày / 25% lãi' },
          ]
          , around: 'VNĐ 25.000.000 - 75.000.000', image: require(`@/assets/img/cat-silver.png`),
          status: [
            { text: 'Chờ xử lý', icon: require(`@/assets/img/waiting.png`) },
            { text: 'Đã mua', icon: require(`@/assets/img/checked.png`) },
            { text: 'Dự trữ', icon: require(`@/assets/img/saved.png`) },
            { text: 'Kháng cáo', icon: require(`@/assets/img/cancel.png`) }
          ]
        },
        {
          name: 'CAT BLACK',
          rose: [
            { text: '3 ngày / 8% lãi' },
            { text: '5 ngày / 13% lãi' },
            { text: '7 ngày / 18% lãi' },
            { text: '12 ngày / 25% lãi' },
          ]
          , around: 'VNĐ 75.000.000 - 190.000.000', image: require(`@/assets/img/cat-black.png`),
          status: [
            { text: 'Chờ xử lý', icon: require(`@/assets/img/waiting.png`) },
            { text: 'Đã mua', icon: require(`@/assets/img/checked.png`) },
            { text: 'Dự trữ', icon: require(`@/assets/img/saved.png`) },
            { text: 'Kháng cáo', icon: require(`@/assets/img/cancel.png`) }
          ]
        },
        {
          name: 'CAT GOLD',
          rose: [
            { text: '1 tháng / 30% lãi' },
            { text: '2 tháng / 40% lãi' },
            { text: '3 tháng / 50% lãi' },
          ]
          , around: 'VNĐ 200.000.000 - 500.000.000', image: require(`@/assets/img/cat-gold.png`),
          status: [
            { text: 'Chờ xử lý', icon: require(`@/assets/img/waiting.png`) },
            { text: 'Đã mua', icon: require(`@/assets/img/checked.png`) },
            { text: 'Dự trữ', icon: require(`@/assets/img/saved.png`) },
            { text: 'Kháng cáo', icon: require(`@/assets/img/cancel.png`) }
          ]
        },
      ]
    }
  },
  mounted() {
    this.getProduct();
    this.getBankConfig();
  },
  methods: {
    getBankConfig() {
      network.get('/configs/get-bank-config').then(res => {
        if (res.data.length > 0) {
          this.bank_config = res.data[0];
          this.bank_config.imageUrl = res.data[0].image;
          this.bank_config.id = res.data[0]._id;
        }
      })
    },
    getProduct() {
      network.get('/products/getProductOfUser').then(res => {
        if (res.data.data) {
          this.active_product = res.data.data.map(product => {
            return {type: product.type_product, status : product.status}
          })
          this.products = res.data.data;
        
        }
      })
    },
    revenue(amount, days, type) {
      let  percent = 0;
      if (amount && days) {
        if (type != 3) {
          if (days == 3) {
                      percent = 8;
                  } else if (days == 5) {
                      percent = 13;
                  } else if (days == 7) {
                      percent = 18;
                  } else {
                      percent = 25;
                  }
        } else {
          if (days == 1) {
                      percent = 30;
                  } else if (days == 2) {
                      percent = 40;
                  } else if (days == 3) {
                      percent = 50;
                  } 
        }
        return this.formatMoney(amount * percent/100);
       }
       return 0;
    },
    serviceCharge(amount, days, type) {
      let  percent = 0;
      if (amount && days) {
        if (type != 3) {
          if (days == 3) {
                      percent = 8;
                  } else if (days == 5) {
                      percent = 13;
                  } else if (days == 7) {
                      percent = 18;
                  } else {
                      percent = 25;
                  }
        } else {
          if (days == 1) {
                      percent = 30;
                  } else if (days == 2) {
                      percent = 40;
                  } else if (days == 3) {
                      percent = 50;
                  } 
        }
      }
      return this.formatMoney(amount + (amount * percent/100));
    },
    service(amount, days, type) {
      let  percent = 0;
      if (amount && days) {
        if (type != 3) {
          if (days == 3) {
                      percent = 8;
                  } else if (days == 5) {
                      percent = 13;
                  } else if (days == 7) {
                      percent = 18;
                  } else {
                      percent = 25;
                  }
        } else {
          if (days == 1) {
                      percent = 30;
                  } else if (days == 2) {
                      percent = 40;
                  } else if (days == 3) {
                      percent = 50;
                  } 
        }
      }
      return this.formatMoney(amount * percent/100 * 15/100);
    },

    tongThanhToan(amount, days, type) {
      let  percent = 0;
      if (amount && days) {
        if (type != 3) {
          if (days == 3) {
                      percent = 8;
                  } else if (days == 5) {
                      percent = 13;
                  } else if (days == 7) {
                      percent = 18;
                  } else {
                      percent = 25;
                  }
        } else {
          if (days == 1) {
                      percent = 30;
                  } else if (days == 2) {
                      percent = 40;
                  } else if (days == 3) {
                      percent = 50;
                  } 
        }
      }
      return this.formatMoney(amount + amount * percent/100 * 15/100);
    },
    ShowInfo(index, i) {
      this.product_show_popup = [];
      this.products.filter(p => {
        if ( p.type_product  == index && p.status == i ) {
          this.product_show_popup.push(p);
        } 
      })

      if (this.product_show_popup.length > 0) {
        this.showPopup = true;
      }
    },

    calculateDate(timestamp, day) {
      return moment(timestamp).add(day, 'days').format(' DD/MM/YYYY');
    },

    
    formatMoney(number) {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      }
      return 0;
        }
  }
}
</script>

<style>
.active {
  background-color: yellow;
  border: 1px solid bisque;
  border-radius: 5px;
  font-weight: bold;
}

.active .status-waiting {
  color: #6a520f !important;

}
.v-dialog__content {
  z-index: 1001;
}
.v-card__text > span {
  display: list-item !important;
}
.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
}
.active.reserved {
  background-color: red;
}
.active.reserved .status-waiting {
  color: white !important;
}

.v-dialog__container {
  display: block !important;
}

.headline {
  font-weight: bold;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Màu của lớp mờ (độ mờ là 0.5) */
  z-index: 10; /* Đảm bảo lớp mờ hiển thị phía trên pack-card */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  z-index: 1000;
}

</style>